import { ActionButton, Button, Flex, Grid, Header, Text, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { useIsSSR } from '@react-aria/ssr';
import { useBreakpoint } from '@react-spectrum/utils';
import { FocusableRefValue } from '@react-types/shared';
import Add from '@spectrum-icons/workflow/Add';
import Light from '@spectrum-icons/workflow/Light';
import Moon from '@spectrum-icons/workflow/Moon';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

interface Props {
  children: ReactNode;
  isDarkMode: boolean;
  setIsDarkMode: Dispatch<SetStateAction<boolean>>;
}

const Layout = ({
  children,
  isDarkMode,
  setIsDarkMode
}: Props) => {
  const { matchedBreakpoints } = useBreakpoint();
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');
  const isSSR = useIsSSR();
  const router = useRouter();

  const postAJobLinkRef = useRef<FocusableRefValue<HTMLAnchorElement> | null>(null);

  const [headerDropShadowIsVisible, setHeaderDropShadowIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;

      if (position === 0) {
        setHeaderDropShadowIsVisible(false);
      } else if (!headerDropShadowIsVisible) {
        setHeaderDropShadowIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerDropShadowIsVisible]);

  useEffect(() => {
    if (isSSR) return;

    const listener = (event: MouseEvent) => {
      event.preventDefault();
    };

    const postAJobLink = postAJobLinkRef.current!.UNSAFE_getDOMNode();

    postAJobLink.addEventListener('click', listener);

    return () => {
      postAJobLink.removeEventListener('click', listener);
    };
  }, [isSSR]);

  const handlePostAJobLinkPress = () => {
    router.push('/post-a-job');
  };

  if (isSSR) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Grid
      minHeight="100vh"
      rows={{
        base: ['size-700', '1fr'],
        S: ['size-1000', '1fr']
      }}
    >
      <Header
        UNSAFE_style={{
          backgroundColor: 'var(--spectrum-global-color-gray-100)',
          boxShadow: headerDropShadowIsVisible ? '0px var(--spectrum-alias-dropshadow-offset-y) var(--spectrum-alias-dropshadow-blur) var(--spectrum-alias-dropshadow-color)' : undefined,
          transition: 'box-shadow 0.2s linear',
          zIndex: 2
        }}
        position="sticky"
        top={0}
      >
        <Flex
          UNSAFE_style={{
            ...isInBaseBreakpointRange ? {
              paddingLeft: 'var(--spectrum-global-dimension-size-100)',
              paddingRight: 'var(--spectrum-global-dimension-size-100)'
            } : {
              paddingLeft: 'var(--spectrum-global-dimension-size-200)',
              paddingRight: 'var(--spectrum-global-dimension-size-200)'
            }
          }}
          alignItems="center"
          height="100%"
          marginX={{ S: 'auto' }}
          maxWidth={{ S: 1280 }}
        >
          <Link
            href="/"
            passHref
          >
            <a
              className="link"
              {...!isInBaseBreakpointRange && { style: { marginLeft: -10 } }}
            >
              <Flex
                alignItems={{ S: 'center' }}
                columnGap={{ S: 'size-50' }}
                marginX={{ S: 'size-150' }}
                marginY={{ S: 'size-50' }}
              >
                <svg
                  height={35}
                  viewBox="0 0 700 700"
                  width={35}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M159.834 690.988c-3.895-10.052 1.242-22.617 9.409-35.988-6.894-1.168-10.52 5.494-21.997-1.205-12.077-7.053-18.252-36.847-19.247-57.173-.93-18.853 2.128-46.39 10.433-65.32 23.253-53.01 67.827-97.995 106.161-97.477 4.518-25.462 26.7-73.329 49.79-86.32 20.121-41.62 34.35-84.888 51.695-128.963 16.243-41.313 27.765-85.88 47.983-126.493 8.383-16.833 17.833-38.1 30.867-53.513C443.298 16.81 494.787-3.35 526.355.468c23.075 2.78 45.897 17.96 45.807 42.582-.082 22.184-22.82 60.359-41.283 91.235-17.598 29.489-40.381 57.802-57.59 84.112-10.123 15.472-17.575 32.139-27.883 48.341-15.094 23.744-34.113 44.727-44.954 66.106-23.54 46.52-37.703 103.137-71.157 143.028 9.834 61.111-46.97 192.356-117.658 219.74-15.64 6.08-34.795 6.96-51.81-4.62zM465.51 66.591c-61.97 80.29-90.574 226.244-152.961 300.626l27.241 15.743c33.328-61.632 59.856-131.668 100.028-193.088 24.428-37.331 50.123-73.65 72.835-114.434 6.558-11.78 16.542-24.856 15.79-39.213-19.223-10.841-49.427 12.869-62.936 30.372zM275.418 435.522l34.052 19.679c10.198-16.846 14.806-32.166 23.481-48.569l-30.642-17.708a78542.658 78616.222 0 0 0-26.892 46.598z"
                    fill="var(--spectrum-global-color-gray-900)"
                  />
                </svg>
                <Text
                  UNSAFE_className="u-font-family-acme"
                  UNSAFE_style={{
                    color: 'var(--spectrum-alias-heading-text-color)',
                    fontSize: 'var(--spectrum-global-dimension-font-size-600)'
                  }}
                  isHidden={{ base: true, S: false }}
                  marginTop={{ S: 'size-50' }}
                >
                  {'Illustration Jobs'}
                </Text>
              </Flex>
            </a>
          </Link>
          <Button
            ref={postAJobLinkRef}
            elementType="a"
            href="/post-a-job"
            marginEnd={{ base: 'size-100', S: 'size-200' }}
            marginStart="auto"
            onPress={handlePostAJobLinkPress}
            variant="cta"
          >
            <Add size="S" />
            <Text>
              {'Post a job'}
            </Text>
          </Button>
          <TooltipTrigger delay={0}>
            <ActionButton
              aria-label={isDarkMode ? 'Switch to light theme' : 'Switch to dark theme'}
              onPress={() => {
                const newIsDarkMode = !isDarkMode;

                localStorage.setItem('isDarkMode', `${newIsDarkMode}`);

                document.documentElement.style.setProperty('color-scheme', newIsDarkMode ? 'dark' : 'light');

                setIsDarkMode(newIsDarkMode);
              }}
            >
              {isDarkMode ? <Light /> : <Moon />}
            </ActionButton>
            <Tooltip>
              {isDarkMode ? 'Switch to light theme' : 'Switch to dark theme'}
            </Tooltip>
          </TooltipTrigger>
        </Flex>
      </Header>
      {children}
    </Grid>
  );
};

export default Layout;
